import { mediacoreApi } from "../mediacoreApi"

const pushExtended = mediacoreApi.injectEndpoints({
  endpoints: builder => ({
    getPush: builder.query({
      query: CLIENT_ID =>
        `push/acciones/${CLIENT_ID}/activas/`
    }),
  }),
  overrideExisting: false,
})

export const { useGetPushQuery } = pushExtended
